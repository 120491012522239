<template>
  <v-card-text>
    <v-autocomplete
      v-model="employer"
      :items="employers"
      :loading="$apollo.queries.users.loading"
      :search-input.sync="search"
      @input="search = null"
      hide-no-data
      item-text="data.meta.first_name"
      item-value="data.ID"
      label="Employer Database"
      placeholder="Start typing to Search"
      prepend-inner-icon="mdi-database-search"
    >
      <template v-slot:item="data">
        <template v-if="typeof data.item !== 'object'">
          <v-list-item-content>
            {{ data.item }}
          </v-list-item-content>
        </template>
        <template v-else>
          <v-list-item-avatar>
            <img :src="data.item.data.meta.et_avatar_url">
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>
              {{ `${data.item.data.meta.first_name} ${data.item.data.meta.last_name}` }}
            </v-list-item-title>
            <v-list-item-subtitle v-html="data.item.group"></v-list-item-subtitle>
          </v-list-item-content>
        </template>
      </template>
    </v-autocomplete>
  </v-card-text>
</template>

<script>
import USERS from '../../graphql/users.graphql'

export default {
  apollo: {
    users: {
      query: USERS,
      variables () {
        return {
          query: {
            paged: this.page,
            number: this.number,
            role: this.role,
            s: this.search
          }
        }
      },
      skip () {
        return !this.search
      },
      fetchPolicy: 'network-only',
      result (ApolloQueryResult) {
        this.employers = ApolloQueryResult.data.users.list
      }
    }
  },
  data () {
    return {
      employer: null,
      search: null,
      employers: [],
      isLoading: false,
      paged: 1,
      number: 10,
      role: 'employer'
    }
  },
  watch: {
    search (val) {
      if (val) {
        this.$apollo.queries.users.start()
      }
    },
    employer (val) {
      this.$emit('employer', val)
    }
  }
}
</script>

<style scoped>

</style>
