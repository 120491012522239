<template>
  <div class="editor" :class="{'message': isMessage}">
    <editor-content class="editor__content" :editor="editor"/>
  </div>
</template>

<script>
// Import the basic building blocks
import { Editor, EditorContent } from 'tiptap'
import { History, Placeholder, Bold, HardBreak } from 'tiptap-extensions'
import CustomLink from '../Utils/CustomLink'
export default {
  components: {
    EditorContent
  },
  props: {
    value: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: 'What is this opportunity or post about? (Required)'
    },
    isMessage: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      // Create an `Editor` instance with some default content. The editor is
      // then passed to the `EditorContent` component as a `prop`,
      something: '',
      editor: new Editor({
        extensions: [
          new CustomLink(),
          new HardBreak(),
          new History(),
          new Bold(),
          new Placeholder({
            emptyEditorClass: 'is-editor-empty',
            emptyNodeClass: 'is-empty',
            emptyNodeText: this.placeholder,
            showOnlyWhenEditable: true,
            showOnlyCurrent: true
          })
        ],
        onInit: () => {
          // editor is initialized
        },
        onUpdate: ({ getHTML, getJSON }) => {
          // get new content on update
          this.$emit('markup', getHTML())
          this.$emit('markupJSON', getJSON())
        }
      })
    }
  },
  mounted () {
    this.editor.setContent(this.value, true)
  },
  beforeDestroy () {
    // Always destroy your editor instance when it's no longer needed
    this.editor.destroy()
  }
}
</script>
<style lang="scss">
  .ProseMirror {
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    height: auto;
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 1.5;
    background-clip: padding-box;
    min-height: 12rem;
    p {
      margin-bottom: 0.5rem;
      margin-top: 0.5rem;
    }
    &:focus {
      outline: 0;
    }
  }
  .editor p.is-editor-empty:first-child::before {
    content: attr(data-empty-text);
    color: #717171;
    float: left;
    font-size: 1.25rem;
    pointer-events: none;
    height: 0;
    &.message {
      font-size: .1rem;
    }
  }
  .editor.message p.is-editor-empty:first-child::before {
    font-size: 1rem;
  }
</style>
