<template>
  <create :current-user="currentUser" :step="step" @onStepChange="handleStep"/>
</template>

<script>
import Create from '../components/Post/Create.vue'
export default {
  data () {
    return {
      step: 1
    }
  },
  props: {
    currentUser: {
      type: Object,
      default: () => {}
    }
  },
  components: {
    Create
  },
  methods: {
    handleStep (step) {
      this.step = step
    }
  },
  beforeRouteLeave (to, from, next) {
    if (this.step === 2) {
      this.step = 1
      next(false)
    } else {
      next()
    }
  }
}
</script>

<style scoped>

</style>
