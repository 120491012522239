<template>
  <v-container fluid class="pa-sm-5 pa-0 create-post-container mobile-height">
    <v-card class="mb-12 form-container fill-height" :loading="loading">
      <v-sheet class="pa-0">
        <v-form ref='createForm' v-model='valid' lazy-validation @submit.prevent='validate()' id='create-form' class='v-text-field--rounded form' loading>
          <employer-search @employer="handleEmployer" v-if="currentUser.role === 'administrator'" />
          <v-window v-model="step">
            <v-window-item :value="1">
              <v-card-text>
                <v-textarea
                  ref='title'
                  solo
                  flat
                  :row-height="2"
                  autofocus
                  required
                  :rules="titleRules"
                  auto-grow
                  placeholder="Title of your Post"
                  no-resize
                  class="textarea"
                  height="auto"
                  v-model='post.title'>
                </v-textarea>
                <editor @markup="handleMarkup" @markupJSON="handleMarkupJSON"/>
                <v-row>
                  <v-col v-if="$apollo.queries.categories.loading">
                    <v-card>
                      <v-skeleton-loader type="list-item-two-line"></v-skeleton-loader>
                    </v-card>
                  </v-col>
                  <v-col v-else>
                    <v-autocomplete
                      outlined
                      ref="category"
                      required
                      deletable-chips
                      :items="compCategories"
                      chips
                      :rules="categoryRules"
                      v-model="post.categories"
                      item-value="id"
                      item-text="name"
                      :loading="loading"
                      :menu-props="autocompleteMenuProps"
                      multiple
                      eager
                      label="Select Categories" />
                  </v-col>
                </v-row>
                <div v-if="$apollo.queries.tags.loading">
                  <v-card>
                    <v-skeleton-loader type="list-item-two-line"></v-skeleton-loader>
                  </v-card>
                </div>
                <div v-else>
                  <h3 class="text--secondary font-weight-medium">Select Tag</h3>
                  <v-radio-group v-model="post.project_type" :mandatory="true" row>
                    <v-radio hide-details class="mr-2 mb-3" :label="item.name" v-for="(item, i) in tags" :key="i" :value="item.id"></v-radio>
                  </v-radio-group>
                </div>
              </v-card-text>
            </v-window-item>

            <v-window-item :value="2">
              <v-card-text>
                <div class="d-flex align-center">
                  <v-btn fab @click="$emit('onStepChange', 1)" v-show="step === 2" depressed text class="mr-3">
                    <v-icon small>mdi-arrow-left</v-icon>
                  </v-btn>
                  <div>
                    <h3>Additional Details</h3>
                    <span class="caption grey--text text--darken-1">Reach out to more people with these features! - Optional</span>
                  </div>
                </div>
                <v-divider class="my-5 mx-3"></v-divider>
                <div class="additional px-2 mb-5">
                  <v-row>
                    <v-col>
                      <v-autocomplete
                        ref="skills"
                        v-model="post.skills"
                        :items="skills"
                        :search-input.sync="searchInput"
                        @input="searchInput = null"
                        :loading="loading"
                        :rules="skillsRules"
                        :counter="10"
                        outlined
                        hide-no-data
                        hide-selected
                        item-text="name"
                        item-value="id"
                        persistent-hint
                        small-chips
                        hint="⚡ Add relevant skills so that people can reach you faster!"
                        label="Skills"
                        :menu-props="autocompleteMenuProps"
                        placeholder="Start typing to fetch skills"
                        prepend-inner-icon="mdi-flask"
                        multiple
                      ></v-autocomplete>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-autocomplete
                        ref='location'
                        v-model="post.location"
                        :search-input.sync="locationInput"
                        @input="locationInput = null"
                        :items="items"
                        :loading="loading"
                        outlined
                        hide-no-data
                        item-text="Description"
                        clearable
                        item-value="API"
                        auto-select-first
                        persistent-hint
                        label="Opportunity Location"
                        placeholder="Start typing to fetch your location"
                        prepend-inner-icon="mdi-map-marker"
                        :menu-props="autocompleteMenuProps"
                      ></v-autocomplete>
                    </v-col>
                  </v-row>
                </div>
              </v-card-text>
            </v-window-item>
          </v-window>
          <v-alert text outlined color='error' icon='mdi-fire' v-if='error' class='my-5 mx-5'>
            {{ error }}
          </v-alert>

          <v-divider v-if="!mdScreen"></v-divider>
          <v-card-text class="pb-0 px-6 error--text text-center" v-if="currentUser && currentUser.meta && currentUser.meta.register_status !== 'confirm'">
            Please Verify your email to post a project
          </v-card-text>
          <v-card-actions class="d-flex justify-space-between pa-6" v-if="!mdScreen">
            <v-btn type='submit' color='primary' x-large :disabled="loading || !(post.title && descriptionValidator.length > 0 && post.categories.length > 0) || (currentUser && currentUser.meta && currentUser.meta.register_status !== 'confirm')" :loading='loading' class="flex-grow-1">
              <template v-slot:loader>
                <span>Loading...</span>
              </template>
              {{ step === 1 ? 'Next' : 'Post to feed' }}
              <v-icon v-if="step === 1">mdi-arrow-right</v-icon>
            </v-btn>
          </v-card-actions>
          <v-card-actions class="d-flex justify-space-between" v-else>
            <v-footer fixed class="py-5">
              <v-btn type='submit' color='primary' x-large :disabled="loading || !(post.title && descriptionValidator.length > 0 && post.categories.length > 0) || (currentUser && currentUser.meta && currentUser.meta.register_status !== 'confirm')" :loading='loading' class="flex-grow-1">
                <template v-slot:loader>
                  <span>Loading...</span>
                </template>
                {{ step === 1 ? 'Next' : 'Post to feed' }}
              </v-btn>
            </v-footer>
          </v-card-actions>
        </v-form>
      </v-sheet>
    </v-card>
  </v-container>
</template>
<script>
import ShortId from 'shortid'
import Editor from './Editor'
import breakPoints from '../../mixins/breakPoints'
import autocomplete from '../../mixins/autocomplete'
import mapsMixin from '../../mixins/mapsMixin'
import EmployerSearch from '../Create/EmployerSearch'
const CATEGORIES = require('../../graphql/queries/categories.graphql')
const ADD_PROJECT = require('../../graphql/addProject.graphql')
const TAGS = require('../../graphql/queries/tags.graphql')
const SKILLS = require('../../graphql/queries/skills.graphql')
const postObj = () => {
  return {
    title: '',
    description: '',
    skills: [],
    location: '',
    project_type: null,
    categories: []
  }
}
export default {
  apollo: {
    tags: {
      query: TAGS
    },
    categories: {
      query: CATEGORIES
    },
    skills: {
      query: SKILLS
    }
  },
  data: () => ({
    valid: true,
    description: {},
    loading: false,
    post: postObj(),
    error: null,
    projectId: null,
    dialog: false,
    items: [],
    categories: [],
    searchInput: null,
    locationInput: null,
    titleRules: [v => (!!v) || 'Field is required'],
    skillsRules: [
      v => v.length < 11 || 'Cannot select more than 10 skills'
    ],
    categoryRules: [v => v.length > 0 || 'Field is required'],
    author: null
  }),
  props: {
    currentUser: {
      type: Object,
      default: () => {}
    },
    step: {
      type: Number,
      default: 1
    }
  },
  computed: {
    // subCategories () {
    //   return (this.categories[this.post.category] && this.categories[this.post.category].map(item => item.name)) || []
    // }
    descriptionValidator () {
      return (this.description.content && this.description.content.filter(item => item.content)) || []
    },
    compCategories () {
      return this.categories.map(item => item.term)
    }
  },
  methods: {
    validate () {
      if (this.step === 1) {
        this.$emit('onStepChange', 2)
      } else {
        if (this.$refs.createForm.validate()) {
          this.submitPost()
        }
      }
    },
    async submitPost () {
      this.loading = true
      try {
        await this.$apollo.mutate({
          mutation: ADD_PROJECT,
          variables: {
            // todo: add employer here
            data: { ...this.post, jobId: ShortId.generate(), author: this.author }
          },
          update: async (store, { data: { addProject } }) => {
            this.$emit('onStepChange', 1)
            await this.$router.replace({ name: 'project', params: { id: addProject } })
          }
        })
        await this.$store.dispatch('setFetchFeed', true)
      } catch (e) {
        this.loading = false
        console.log(e)
        this.error = e
      }
    },
    handleMarkup (markup) {
      this.post.description = markup
    },
    handleMarkupJSON (markup) {
      this.description = markup
    },
    handleEmployer (val) {
      this.author = val
    }
  },
  components: {
    Editor,
    EmployerSearch
  },
  watch: {
    'post.category' () {
      this.post.subCategory = ''
    }
  },
  mixins: [mapsMixin, breakPoints, autocomplete]
}
</script>
<style lang='scss'>
  .create-post-container {
    border-radius: 4px;
    .textarea {
      padding-left: 0;
      font-size: 2rem;
      min-height: 2rem;
      font-weight: bold;
      textarea {
        line-height: 2.5rem !important;
      }
    }
    h2 {
      letter-spacing: 2px;
      span {
        border-bottom: 3px solid ;
      }
    }
  }
  .additional {
    .check {
      border: 1px dashed var(--v-info-base);
    }
  }
</style>
